import React from "react";
import loadable from "@loadable/component";
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links";
import {
    CATCHUP_SECTION_BLUR_WIDTH,
    CATCHUP_SECTION_BLUR_HEIGHT,
} from "@constants";
import { GlobalStyle } from "../Styles/GlobalStyle";
import WebFAQSection from "./components/FAQSection/FAQSection";
import backgroundImage from "@images/web-development/backgroundImage.jpg";
import { 
    SECOND_CATCH_UP_SECTION_TITLE, 
    SECOND_CATCH_UP_SECTION_DESCRIPTION,
    FEEDBACK_SECTION_ITEMS
} from "../constants";

const CircleBLur = loadable(() => import("@common/CircleBlur/CircleBlur"));

const ProcessSection = loadable(() =>
    import("./components/ProcessSection/ProcessSection")
);

const FeedbackSection = loadable(() => import("@common/SlideShow/ContentWithFeedback/FeedbacksSection/FeedbacksSection"));

const WhyChooseSection = loadable(() => import("./components/WhyChooseSection"));

const OffersSection = loadable(() =>
    import("./components/OffersSection/OffersSection")
);

const TechnologySection = loadable(() =>
    import("./components/TechnologySection/TechnologySection")
);

const TextTitleSection = loadable(() =>
    import("@common/CommonSections/TextTitleSection/TextTitleSection")
);

const CrawlableSecondCatchUpSection = loadable(() =>
    import("@common/CommonSections/CrawlableSecondCatchUpSection")
);

const LinkButton = loadable(() => import("@common/LinkButton"));

const WebDevelopment = () => (
    <div>
        <GlobalStyle />
        <TextTitleSection
            title="Web application development"
            description={`
                    When deciding to turn your business idea into a real product and are looking to hire web developer, 
                    you want to find a reliable and knowledgeable web app development partner. Impressit 
                    is the one you were looking for.
                    <br/>
                    We are a team you can trust. We are professionals who are ready to develop an elegant, 
                    scalable, and secure web application of any complexity for any industry.
                `}
            backgroundImage={backgroundImage}
        />
        <OffersSection />
        <ProcessSection />
        <TechnologySection />
        <FeedbackSection
            feedbacks={FEEDBACK_SECTION_ITEMS}
            title="Our Clients say"
        />
        <WhyChooseSection />
        <WebFAQSection />
        <div className="catch-up-section__container">
            <CircleBLur
                width={CATCHUP_SECTION_BLUR_WIDTH}
                height={CATCHUP_SECTION_BLUR_HEIGHT}
            />
            <CrawlableSecondCatchUpSection
                title={SECOND_CATCH_UP_SECTION_TITLE}
                description={SECOND_CATCH_UP_SECTION_DESCRIPTION}
                >
                <LinkButton to={GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US}>
                    Contact us
                </LinkButton>
            </CrawlableSecondCatchUpSection>
        </div>
    </div>
);

export default WebDevelopment;
